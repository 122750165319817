import { computed, shallowRef, watch } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import { authService } from '@exchange/libs/auth/service/src';
import { futuresService } from '@exchange/libs/futures/service/src';
import FuturesAppropriatenessRetakeModal from '@exchange/libs/futures/ui/src/lib/components/FuturesAppropriatenessRetakeModal.vue';
import { modalService } from '@exchange/libs/modals/src';
import { FuturesAppropriatenessResultStatus } from '@exchange/libs/rest-api/web-api/customer/futures-appropriateness-resource';
import { settingsService } from '@exchange/libs/settings/service/src';
import { toastManagerInstance, SimpleToast } from '@exchange/libs/toasts/src';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import handleNativeGoTo from '@exchange/libs/utils/capacitor/src/lib/handle-native-go-to';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { checkIsBiometricRoute } from '@exchange/routing';

export const useGlobalNotificationModals = (route: RouteLocationNormalized) => {
  const shouldShowModals = computed(() => !checkIsBiometricRoute(route) && authService.isAuthenticated);

  const handleFuturesAssessmentRetakeReminder = async () => {
    const assessmentResult = await futuresService.getFuturesAppropriatenessAssessmentResult();

    if (assessmentResult?.status === FuturesAppropriatenessResultStatus.FAILED && assessmentResult.canRetake) {
      const futuresAppropriatenessAssessmentRetakeUrl = `${process.env.VUE_APP_ACCOUNTS_URL}${CONSTANTS.FUTURES_PATHNAME}/appropriateness-test/retake`;

      const handleModalDismissal = () => {
        try {
          settingsService.setSettings({
            path: 'futuresSettings.retakeEligibilityResult',
            value: new Date().toString(),
          });
        } catch (error) {
          logger.error('Failed to set futures appropriateness retake eligibility status setting');
        }
      };

      const mKey = modalService.show(
        shallowRef(FuturesAppropriatenessRetakeModal),
        {
          canDismiss: true,
        },
        {
          dismiss: () => {
            modalService.hide(mKey);
            handleModalDismissal();
          },
          retake: () => {
            if (capIsNativePlatform()) {
              handleNativeGoTo(futuresAppropriatenessAssessmentRetakeUrl, undefined, () => {
                toastManagerInstance.addToast({
                  content: SimpleToast,
                  props: {
                    variant: 'failed',
                    title: 'fundamentals.toasts.failed',
                    message: 'fundamentals.error.text',
                  },
                });
              });
              modalService.hide(mKey);
              handleModalDismissal();
            } else {
              window.location.href = futuresAppropriatenessAssessmentRetakeUrl;
            }
          },
          'modal-close': handleModalDismissal,
          'modals-container-on-outside-click': handleModalDismissal,
        },
        { canClose: true },
      );
    }
  };

  watch(shouldShowModals, async (newShouldShowModals) => {
    if (!newShouldShowModals) {
      return;
    }

    if (settingsService.settings.userSettings?.futuresSettings?.retakeEligibilityResult) {
      return;
    }

    await handleFuturesAssessmentRetakeReminder();
  });
};

export default useGlobalNotificationModals;
